@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg);
	color: white;
	opacity: 0;
	height: 16px;
	width: 16px;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
	opacity: 1;
}

/* You can add this to your CSS file */
.transition-all {
	transition: all 0.3s ease-in-out;
	/* Adjust timing as needed */
}


/* Custom scrollbar styles */
.custom-scrollbar {
	scrollbar-width: none;
	/* Firefox */
	scrollbar-color: #888 #e0e0e0;
	/* Firefox */
}

/* For Chrome, Edge, and Safari */
.custom-scrollbar::-webkit-scrollbar {
	width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
	background: #e0e0e0;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background-color: #888;
	border-radius: 10px;
	border: 2px solid #e0e0e0;
}

.transition-all {
	transition: all 0.5s ease-in-out;
}

.opacity-0 {
	opacity: 0;
}

.opacity-100 {
	opacity: 1;
}

.max-h-0 {
	max-height: 0;
}

.max-h-screen {
	max-height: 100vh;
	/* or adjust to fit your design */
}


@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: scale(0.75);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
		transform: scale(1);
	}

	100% {
		opacity: 0;
		transform: scale(0.75);
	}
}

/* Change filter icon color when active */
.ant-table-filter-trigger-active {
	color: #1890ff;
	/* Change this color as per your design preference */
}

/* Optionally, you can add a hover effect to the filter icon */
.ant-table-filter-trigger:hover {
	color: #40a9ff;
}